.Education {
    outline: thin solid #6aa84f;
    background-color: #d9ead3;
}

.EducationTimeline {
    padding-top: 38px;
}

.Education-section {
    font-family: comfortaa;
    font-size: x-large;
    padding-bottom: 25px;
}

.Education-title {
    font-family: comfortaa;
    border-right: 2px dashed var(--line);
}

.Education-year {
    text-align: left;
    vertical-align: bottom;
    border-left: 3px dashed var(--line);
    padding: 5px;
    font-size: xx-small;
}

.DegreeProgram {
    border-right: 3px solid var(--line);
    display: table;
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
    margin-left: 10px;
    width: calc(100% - 10px);
}

.DegreeProgram td {
    font-size: small;
    padding-left: 10px;
}

.DegreeProgram div {
    font-weight: bold;
}
