.Bullet {
    font-size: small;
    margin-bottom: 8px;
}

.Employer {
    font-size: normal;
    font-weight: bold;
    margin: 0;
}

.Experience-section {
    font-family: comfortaa;
    font-size: x-large;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: left;
    width: 100%;
}

.Experience-year {
    text-align: left;
    vertical-align: bottom;
    border-left: 3px dashed var(--line);
    font-size: xx-small;
    padding: 5px;
}

.Experience table {
    margin-top: 20px;
    padding-left: 20px;
}

.Heading {
    font-size: small;
    font-weight: bold;
}

.JobDetails {
    border: 1px solid #9fc5e8;
    background-color: #fcfcfc;
    position: absolute;
    top: 0;
    left: 10px;
    height: calc(100% - 15px);
    width: calc(100% - 148px);
}

.Paragraphs {
    list-style-type: circle;
    margin-top: 2px;
    margin-bottom: 0;
    padding-left: 25px;
}

.Title {
    font-style: italic;
    font-size: small;
}
