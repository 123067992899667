:root {
    --line: cornflowerblue;
    --bg: #ffffff;
}

.Bubble {
    direction: rtl;
    display: flex;
}

.Bubble table {
    border-spacing: 0;
    margin: -1px;
}

.Bubble tr {
    display: flex;
}

.BubbleBorder {
    height: 40px;
    margin: 0;
    padding: 0;
    outline: 2px solid var(--line);
    border-radius: 5px;
    background: var(--bg);
}

.BubbleIcon {
    width: 40px;
}

.BubbleSegment {
    display: inline-block;  /* needed? */
    height: 0;
    width: 30px;
    border: 1px solid var(--line);
    padding: 0;
    margin-top: 20px;
}

.Edge {
    border-left: 3px solid var(--line);
    padding-left: 3px;
    font-size: xx-small;
    text-align: center;
}

.Timeline {
    text-align: left;
    width: 100%;
}

table[class*='Timeline'] {
    border-spacing: 0;
}

.TimeSpan {
    position: relative;
}
