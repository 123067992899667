
.Resume {
    background-color: white;
    border-spacing: 0;
    margin: 1% 15% 1% 15%;
    outline: thin solid lightgray;
    padding: 20px;
}

thead {
    display: contents;
}

.University {
    width: 25%;
}

.Work {
    max-width: 75%;
}

.Name {
    font-family: comfortaa;
    width: calc(100% - 20px);
    padding-left: 20px;
    text-align: left;
}

.Objective {
    font-family: comfortaa;
    outline: thin solid gold;
    background-color: #fff3cc;
    padding: 20px;
    line-height: 1.2;
    font-size: small;
}
