.ContactInfo {
    padding-left: 8px;
    display: inline-block;
}

.ContactInfo a {
    font-size: small;
    padding-top: 4px;
    padding-bottom: 4px;
}

.ContactInfo div {
    font-size: xx-small;
}
