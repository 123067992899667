.Skills {
    border: 1px solid gray;
}

.SkillCategories {
    font-family: comfortaa;
    font-size: large;
    text-align: left;
    color: gray;
}

.SkillDetails {
    font-size: small;
}

.SkillsHeading {
    font-family: comfortaa;
    font-size: xx-large;
    max-width: min-content;
}
